import React from 'react';
import {Typography} from "@material-ui/core";

export default ({ children }) => (
    <Typography
      variant={'overline'}
      color={'textSecondary'}
      style={{ textTransform: 'uppercase' }}
    >
      {children}
    </Typography>
  )