import { Grid, Typography } from '@material-ui/core';
import { filter, flow, map } from 'lodash/fp';
import { grey, red } from '@material-ui/core/colors';

import Img from 'gatsby-image';
import { Link } from 'gatsby';
import MaybeWrapper from './MaybeWrapper';
import React from 'react';

export default ({ allMdx }) => {
  return (
    <Grid container spacing={4}>
      {flow(
        (allMdx) => allMdx.edges,
        filter((edge) => edge.node.fields.released === true),
        map(({ node }) => {
          const cover = node.frontmatter?.cover?.childImageSharp?.fixed;
          const slug = node.fields.slug;
          const title = node.frontmatter.title || node.fields.slug;
          const isReleased = node.fields.releasedNotForced === true;
          return (
            <Grid key={node.fields.slug} item xs={12} sm={6} md={4}>
              <MaybeWrapper
                check={slug}
                wrap={({ children }) => (
                  <Link style={{ boxShadow: `none` }} to={slug}>
                    {children}
                  </Link>
                )}
              >
                <div
                  style={{
                    border: `2px solid ${grey[900]}`,
                    borderRadius: 6,
                    overflow: 'hidden',
                    opacity: isReleased ? 1 : 0.35
                  }}
                >
                  {!!cover && false ? (
                    <Img
                      style={{
                        width: '100%',
                        height: '140px',
                      }}
                      fixed={cover}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'none',
                        height: '140px',
                        backgroundColor: grey[700],
                      }}
                    />
                  )}
                  <div style={{
                    padding: '14px 18px',
                  }}>
                  <Typography
                    variant={'h5'}
                    style={{
                      fontWeight: 900,
                      lineHeight: 1.25,                                            
                    }}
                  >
                    {title}
                  </Typography>
                  {!isReleased ? <Typography>Draft</Typography> : null}
                  </div>
                </div>
              </MaybeWrapper>
            </Grid>
          );
        })
      )(allMdx)}
    </Grid>
  );
};
