import { Button, Typography } from '@material-ui/core';
import { Link, graphql } from 'gatsby';
import { blue, pink, purple, red, yellow } from '@material-ui/core/colors';
import { flow, map, reverse } from 'lodash/fp';

import AppPreview from '../components/AppPreview';
import Bio from '../components/Bio';
import { EmbeddedVideo } from 'react-facebook';
import HeroImage from '../components/HeroImage';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import { Post } from '../components/Post';
import PostsGrid from '../components/PostsGrid';
import React from 'react';
import RescueTimeStats from '../components/RescueTimeStats';
import SEO from '../components/seo';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import { SubscriptionForm } from '../components/SubscriptionForm';
import TWEETS from './tweets.json';
import Tweet from '../components/Tweet';
import image from './cover.png';
import videoSrc from './cover.mp4';

const SECTION_DATA_FOR_GUIDES = [
  {
    title: '10x Better Workflow',
    slug: '/10x-better-workflow',
  },
  {
    title: '10x Better Gatsby',
    slug: '/10x-better-gatsby',
  } /*{
  title: '8-th Note Tripley Layer',
  slug: '/course-8ntl'
}*/,
];

const SECTION_DATA_FOR_ARTICLES = [];
const SECTION_DATA_FOR_MISC = [];
const SECTION_DATA_FOR_OPEN_SOURCE = [];

const SECTION_DATA_FOR_INDIE = [
  {
    title: 'Project #01, Web-portal for an online-game',
    excerpt:
      'I was a kid. It was fun. It made me thousands of dollars. THAT was neat.',
  },
  {
    title: 'Project #02, Online Class Booking Tool',
    excerpt: 'A project about my hobby - dancing',
  },
  {
    title: 'Project #03, SwingPulse Website v1',
    tags: ['web'],
    excerpt: 'A project about my hobby - dancing',
  },
  {
    title: 'Project #04, Google Home Assistant Mac OS Timers App',
    tags: ['web'],
    excerpt: 'A project about my hobby - dancing',
  },
  {
    title: 'Project #05, Wheel of Life',
    excerpt: 'It was a project about my hobby - dancing',
  },
  {
    title: 'Project #06, Emojional Habit Tracker',
    excerpt: 'Habit tracekr using emojis',
  },
  {
    title: 'Project #07, SwingPulse Website v2',
    tags: ['web'],
    excerpt: 'A project about my hobby - dancing',
  },
  {
    title: 'Project #08, Metromoe Android App',
    excerpt: 'It was a project about my hobby - dancing',
  },
  {
    title: 'Project #09, Trackerion — PAIN-FREE Journaling App',
    excerpt: 'Journaling using emojis',
    slug: 'trackerion',
  },
];

const SectionLinks = ({ data }) => {
  return data.map((item) => (
    <Post
      key={item.slug}
      slug={item.slug}
      disabled={!item.slug}
      title={item.title}
      divider={
        SECTION_DATA_FOR_GUIDES.indexOf(item) !==
        SECTION_DATA_FOR_GUIDES.length - 1
      }
      excerpt={item.excerpt}
    />
  ));
};

class MainPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    const countOfSubscribers =
      data.allMailchimpList.edges[0].node.stats.member_count;

    return (
      <Layout location={this.props.location} title={''} fullWidth>
        <SEO
          title='Home'
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <video width='100%' src={videoSrc} loop muted autoplay={'true'} />

        <Section>
          <Typography
            variant={'h3'}
            style={{
              fontWeight: 'bold',
              lineHeight: 1.05,
            }}
            gutterBottom
          >
            Hi! I'm Dmytro Rohov
          </Typography>
          <Typography paragraph variant={'h5'}>
            I am sharing practical tips on how to create an{' '}
            <b>optimal entrepreneurial life</b>.
          </Typography>
          <Typography paragraph>
            Mine is about heathy mix of products building, swing dancing,
            martial arts, family time using habits, mindset & tools. I haven't
            mastered the balance yet, but I'm on the way.
          </Typography>
          <Typography paragraph>
            Let's connect to go further together!
          </Typography>
        </Section>

        {/*        
        <>
          <br />
          <br />
          <SectionHeader>MY STORY</SectionHeader>
          <Typography gutterBottom>
            I am documenting the process of transforming from a single
            freelancer coder to a business owner of a set of sucesful web
            busineses.
          </Typography>
          <SectionLinks
            data={flow(
              reverse,
              map((data) => {
                const index = SECTION_DATA_FOR_OPEN_SOURCE.indexOf(data);
                return {
                  ...data,
                  title: `${data.title}`,
                };
              })
            )(SECTION_DATA_FOR_INDIE)}
          />
        </>
          */}

        <Section title={'Join'} color={red[900]}>
          <Typography
            gutterBottom
            variant={'h3'}
            style={{ fontWeight: 'bold' }}
          >
            Transformative Information, Relatable To You
          </Typography>
          <Typography paragraph variant={'h5'}>
            I share information that helps me to come one step closer to
            suceeding in the field of creative enterpreneurship. All backed up
            my real, hopefully relatable, experience.
          </Typography>
          <SubscriptionForm countOfSubscribers={countOfSubscribers} />
        </Section>

        <Section title={'Read'} rightIsFull={true}>
          <Typography
            variant={'h1'}
            style={{
              fontWeight: 'bold',
            }}
          >
            Issues
          </Typography>
          <SectionHeader>Latest posts</SectionHeader>
          <PostsGrid allMdx={data.allMdx} />
        </Section>
        <Section title={'Short Form'}>
          <Typography
            variant={'h1'}
            style={{
              fontWeight: 'bold',
              lineHeight: 1,
            }}
            gutterBottom
          >
            Tweets
          </Typography>
          {TWEETS.slice(0, 5).map((data) => (
            <Tweet key={data.content} {...data} />
          ))}

          <div
            style={{
              float: 'right',
            }}
          >
            <Link to={'/tweets'}>
              <Button variant={'outlined'}>View all</Button>
            </Link>
          </div>
        </Section>
        <Section title={'Tools'}>
          <Typography
            variant={'h1'}
            style={{
              fontWeight: 'bold',
              lineHeight: 1,
            }}
            gutterBottom
          >
            I've build this
          </Typography>
          <AppPreview
            slug={'/p/energon'}
            title={'Energy Tracker — Know Yourself'}
            description={'An android tool to know when you are good.'}
          />
          <AppPreview
            slug={'/p/trackerion'}
            title={'Trackerion — Fast & Visual Journaling'}
            description={'An android tool to become more aware.'}
          />
          <AppPreview
            slug={'/p/habits-with-feelings'}
            title={'Habit Tracker with Feelings'}
            description={'A web app to track your habits with emojis.'}
          />
          <AppPreview
            slug={'/focutron'}
            title={'Assistant Timers Mac OS'}
            description={
              'A MacOS app to display Google Assistant timers on menu bar.'
            }
          />
          <AppPreview
            slug={'https://wheel-of-life.netlify.app/'}
            title={'Life Review — Wheel of Life Overview'}
            description={
              'A short quiz to review your life within the wheel of life spectrum.'
            }
          />
        </Section>
        {/*
        <Section title={'Guides'}>
          <SectionHeader>GUIDES</SectionHeader>
          <SectionLinks data={SECTION_DATA_FOR_GUIDES} />
        </Section>
        }
        <Section title={'Misc'}>
          <SectionHeader>Misc</SectionHeader>
          <Post slug={'/interested'} title={'My Interests'} />
          <Post slug={'/goals'} title={'My Goals'} />
          <Post slug={'/habits'} title={'My Habits'} divider={false} />
        </Section>
      */}

        <Section title={'Bio'}>
          <Bio />
        </Section>
      </Layout>
    );
  }
}

export default MainPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMailchimpList {
      edges {
        node {
          id
          stats {
            member_count
          }
        }
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { released: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            released
            releasedNotForced
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            cover {
              publicURL
              childImageSharp {
                fixed(width: 240, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
