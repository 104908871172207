import { Button, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

import { Link } from 'gatsby';
import React from 'react';

const AHREF = (props) => <a target={'_blank'} {...props} />;

export default ({ title, description, slug, cover }) => {
  const Component = slug.match('https:') ? AHREF : Link;

  return (
    <Component to={slug} href={slug}>
      <div
        style={{
          padding: '20px',
          border: `4px solid ${grey[900]}`,
          borderRadius: 8,
          marginBottom: '40px',
        }}
      >
        <Typography
          variant={'h4'}
          style={{
            fontWeight: 'bold',
          }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography gutterBottom>{description}</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Button>Learn more</Button>
        </div>
      </div>
    </Component>
  );
};
